//
// _dropdown.scss
//

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  font-size: 15px;
  line-height: 15px;
  content: "\F0140" !important;
  font-family: "Material Design Icons";
}

.dropdown-menu {
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  // animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;

  &.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
  }

  // &[data-popper-placement="top-start"] {
  //     animation-name: DropDownSlideDown;
  // }
}

// @keyframes DropDownSlide {
//     100% {
//         margin-top: -1px;
//     }

//     0% {
//         margin-top: 8px;
//     }
// }

// @keyframes DropDownSlideDown {
//     100% {
//         margin-bottom: 0;
//     }

//     0% {
//         margin-bottom: 8px;
//     }
// }

@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 420px;
  }

  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-height-md {
  height: 200px;
}

.dropdown-input-icon {
  position: absolute;
  z-index: 10;
  font-size: 20px;
  line-height: 30px;
  right: 7px;
  top: 0;
}

.dropdown-toggle-split {
  border-left: none;
  &::after {
    margin-left: 0px;
  }

  &:before {
    content: "";
    position: absolute;
    background-color: rgba(250, 250, 250, 0.12);
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: 0;
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 38rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

[dir="ltr"] {
  .dropdown-menu-start {
    --bs-position: end;
  }

  .dropdown-menu-end {
    --bs-position: start;
  }
}
// Custom Dropdown with Tabs
.dropdown-head {
  .nav-tabs-custom {
    border: 0;
    .nav-link {
      color: #282323;
      &.active {
        background-color: rgb(250, 250, 250);
        &:hover {
          color: #1d1d1d;
        }
      }
      &:hover {
        color: #fff;
      }
    }
  }
}

// @each $color,
// $value in $theme-colors {
.dropdownmenu-primary {
  .dropdown-item {
    &:focus,
    &:hover,
    &.active {
      background-color: #6691e7;
      opacity: 0.7;
      color: #fff;
    }
  }
}
// }
