.patient-page {
  padding-top: 6rem;
  padding-bottom: 2rem;
}

.mb-patient-dropdown {
  top: 55px !important;
  left: 0;
}

.patient-accordion-item {
  border: none;
}

.patient-accordion {
  .accordion-body {
    padding-left: 0;
    border: none;
    padding-right: 0;
  }
}

.patient-addmission-complete {
  left: -10px;
  top: -10px;
}

.draft-bill {
  background-color: rgba($color: #ff6000, $alpha: 0.3);
}

.draft {
  height: 50px;

  .draft-invoice {
    cursor: pointer;
    transition: 0.5s;

    :nth-child(1) {
      height: 5px;
      width: 80px;
      margin-top: px;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: #ff6000;
    }

    :nth-child(2) {
      // position: relative;
      height: 15px;
      width: 30px;
      // margin-top: -6px;
      margin-left: -7px;
      background: #ff6000;
      color: #ff6000;
      border-radius: 5px;
    }

    &:hover {
      :nth-child(2) {
        // content: "Draft";
        transition: 0.5s;
        background: #8576ff;
        color: #ffffff;
        height: 40px;
        width: 60px;
      }
    }
  }
}

.patient-list {
  @media (max-width: 1024px) {
    min-height: 400px !important;
    max-height: 400px !important;
    overflow-x: auto;
  }
}

.centers-alert {
  width: 100%;
  @media (min-width: 780px) {
    width: 50% !important;
  }
}

.patient-menu-btn {
  @media (max-width: 992px) {
    display: none;
  }
}

.patient-menu-btn {
  @media (min-width: 1024px) {
    // 1200
    display: none !important;
  }
}

.patient {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  span {
    &:nth-child(1),
    &:nth-child(2) {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  @media (max-width: 1023.98px) {
    //62
    margin-top: 1rem;
    border: none;
    padding-left: 0;
    background-color: #fff;
    margin-bottom: 1rem;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  }
}

.patients:hover {
  background-color: #fff;
  cursor: pointer;
}

.patient-profile {
  display: flex;

  div {
    div {
      span {
        margin-right: 0.5rem;
      }
    }
  }
}

.patient-data {
  @media (max-width: 1025px) {
    padding-left: 12px !important;
  }

  @media (min-width: 1023.98px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (max-width: 1023.98px) {
    //max-width: 1199.98
    padding-left: 10px !important;
    padding-right: 10px;
  }
}

.charting-line {
  position: absolute;
  width: 2px;
  top: 2rem;
  margin: auto;
  left: -2rem;
  z-index: -1;
  height: calc(100% - 2rem);
  background: rgba(0, 0, 0, 0.3);
  margin: auto;

  @media (max-width: 767px) {
    left: -1rem !important;
  }
}

.chart-banner {
  position: relative;
  @media (max-width: 767px) {
    padding-left: 3rem !important;

    &::before {
      position: absolute;
      content: "";
      width: 15px;
      border-radius: 10px;
      height: 15px;
      background-color: rgb(120, 113, 113);
      top: 1.1rem;
      left: -1.44rem;
    }
  }
}

.chart-avatar {
  position: absolute;
  top: 5px;
  left: -2.9rem;

  @media (max-width: 767px) {
    left: 10px;
    top: 11px;

    .payment-avatar {
      top: 15px;
    }
  }
}

.text-align-right {
  text-align: right;
}

.charts-row {
  --bs-gutter-x: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.patient-contact {
  @media (max-width: 992px) {
    display: none;
  }
}

.toggle-patient-bar {
  @media (max-width: 1300px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 1rem;
  }
  @media (max-width: 1023.98px) {
    //1199.98
    width: 100% !important;
  }
}

//small device patient search box
.xs-search {
  form {
    @media (max-width: 767px) {
      display: block !important;
    }
  }
}

//bill table
.bill-table {
  @media (max-width: 700px) {
    width: 700px;
  }
}

.aadhaar-card {
  width: 200px;
}

.patient-content {
  background: url("../../images/chat-bg-pattern.png");
  transition: all 0.4s;
  position: relative;
  height: calc(100vh - 260px);
  background-color: var(--#{$variable-prefix}body-bg);

  @media (min-width: 700px) {
    height: calc(100vh - 150px);
  }
}
