.calendar-td:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.calendar-td-h {
  height: 100px !important;
}

.calendar-td-w {
  width: 100px !important;
  border: 1px solid #d5d4d4 !important;
}

.day-time-w {
  width: 10%;
}

.day-block-w {
  width: 90%;
}

/* booking sidebar */
.booking-sidebar {
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid #1d1d1d;
}

.treatment-head {
  @media (max-width: 800px) {
    display: none;
  }
}

.schedule-content-table {
  //   border: none none;
  //   border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

.e-time-cells-wrap {
  position: relative;
}

.e-content-wrap {
  overflow: auto;
}

.e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  overflow: hidden;
  position: absolute;
}
