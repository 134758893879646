//
// _vertical.scss
// 

.app-content {
  margin-left: 250px;
  overflow: hidden;

  .content {
      padding: 0 15px 10px 15px;
      margin-top: 70px;
  }
}

.main-content {
  transition: all .1s ease-out;

  @media (min-width: 1200px) { 
      margin-left: 250px;
  }
}

@media (min-width: 1200px) {
    [data-layout="vertical"][data-sidebar-size="sm"] {
      min-height: 1400px !important; 
    }
    [data-layout="vertical"][data-sidebar-size="sm"] .main-content {
        margin-left: 70px !important; 
    } 
}

.page-content {
  padding: calc(70px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2);

  @media (max-width: 1300px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.navbar-menu {
  width: 250px;
  z-index: 1002;
  background: #FFF;
  border-right: 1px solid #FFF;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 4px rgba(15,34,58,0.12);
  padding: 0 0 calc(70px + 25px) 0;
  transition: all .1s ease-out;

  .navbar-nav {
      .nav-link {
          display: flex;
          align-items: center;
          padding: 0.625rem  1.5rem;
          color: dodgerblue;
          font-size: 0.925rem;
          font-family: 'Rubik', sans-serif;

          &.active {
              color: black;
          }

          &:hover {
              color: rgb(3, 100, 197);
          }

          i {
              color: dodgerblue;
              display: inline-block;
              min-width: 1.75rem;
              font-size: 18px;
              line-height: inherit;
          }

          svg {
              width: 18px;
              margin-right: 0.665rem;
              color: dodgerblue;
          }

          .badge {
              margin-left: auto;
              margin-right: -2px;
              z-index: 1;
          }

          &:hover {
              color: rgb(3, 100, 197);

              .icon-dual {
                  color: rgb(3, 100, 197);
                //   fill: rgba($primary, 0.16);
              }
          }

          &[data-bs-toggle=collapse] {
              &:after {
                  display: block;
                  content: "\F0142";
                  font-family: 'Material Design Icons';
                  margin-left: auto;
                  transition: transform .2s;
                  font-size: 1.05rem;
                  position: absolute;
                  right: 18px;
                  color: #1d1d1d;
              }

              &[aria-expanded=true] {
                  color: dodgerblue;

                  .icon-dual {
                      color: dodgerblue;
                    //   fill: rgba($primary, 0.16);
                  }

                  &:after {
                      transform: rotate(90deg);
                      color: dodgerblue;
                  }

                  &:before {
                      opacity: 1;
                    //   background-color: dodgerblue;
                  }
              }
          }
          &.collapsed {
              &.active {
                  &::after {
                      transform: rotate(90deg);
                  }
              }
          }
      }

      .nav-sm {
          padding-left: 1.75rem;

          .nav-link {
              padding: 0.45rem 1.5rem !important;
              color: dodgerblue;
              white-space: none;
              position: relative;
              font-size: 0.875rem;
              font-family: 'Rubik', sans-serif;

              &:before {
                  content: '';
                  width: 6px;
                  height: 1.5px;
                  background-color: rgb(110, 165, 220);
                  position: absolute;
                  left: 2px;
                  top: 16.5px;
                  transition: all .4s ease-in-out;
                  opacity: 0.5;
              }

              &:hover {
                //   color: rgb(3, 100, 197);
                opacity: 0.8;

                  &:before {
                      background-color: #1d1d1d !important;
                      opacity: 1;
                  }
              }

              &.active {
                  color: #1d1d1d;

                  &:before {
                      background-color: #1d1d1d;
                  }
              }
          }

          .nav-sm {
              padding-left: 15px;

              .nav-link {

                  &:before {
                      height: 5px;
                      width: 5px;
                      left: 5px;
                      border-radius: 50%;
                      background-color: transparent;
                      border: 1px solid;
                      top: 16px;
                  }
              }
          }
      }
  }

  .btn-vertical-sm-hover {
    //   color: gainsboro;
      display: none;
  }
}

.navbar-brand-box {
  padding: 0 1.3rem;
  text-align: center;
  transition: all .1s ease-out;

  @media (max-width: 767.98px) {
      display: none;
  }
}

.vertical-menu-btn {
    @media (min-width: 1200px) {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    [data-sidebar-size="sm"] {
        #page-topbar{
            left: 70px !important;
        }
    }
}

@media (max-width: 1199.98px) {
    #page-topbar{
        left: 0px !important;
    }
}

.hamburger-icon {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;

  span {
      background-color: gainsboro;
      position: absolute;
      border-radius: 2px;
      transition: .3s cubic-bezier(.8, .5, .2, 1.4);
      width: 100%;
      height: 2px;
      display: block;
      left: 0px;

      &:nth-child(1) {
          top: 0;
          width: 80%;
      }

      &:nth-child(2) {
          top: 6px;
      }

      &:nth-child(3) {
          bottom: 0;
          width: 60%;
      }
  }

  .vertical-menu-btn:hover &:not(.open) {
      span {

          &:nth-child(1) {
              top: -1px;
          }

          &:nth-child(3) {
              bottom: -1px;
          }
      }
  }

//   @media (max-width: 1300px) {
//     &.open {
//         transform: rotate(-90deg);
  
//         span {
  
//             &:nth-child(1) {
//                 left: 1px;
//                 top: 5px;
//                 width: 20px;
//                 transform: rotate(90deg);
//                 transition-delay: 150ms;
//             }
  
//             &:nth-child(2) {
//                 left: 3px;
//                 top: 13px;
//                 width: 10px;
//                 transform: rotate(45deg);
//                 transition-delay: 50ms;
//             }
  
//             &:nth-child(3) {
//                 left: 9px;
//                 top: 13px;
//                 width: 10px;
//                 transform: rotate(-45deg);
//                 transition-delay: 100ms;
//             }
//         }
//     } 
//   }
}

.logo {
  line-height: 70px;

  .logo-sm {
      display: none;
  }
}

.logo-light {
  display: none;
}

[data-layout="vertical"] {
  .app-menu {
      .row {
          margin: 0;

          >* {
              width: 100%;
              padding: 0;
          }
      }

      @media (max-width: 1199.98px) {
          margin-left: -100%;
          padding: 10px 0 20px 0;
      }
  }

  .navbar-menu {
      .container-fluid {
          padding: 0;
      }
  }

  .navbar-brand-box {
      @media (max-width: 767.98px) {
          display: none;
      }
  }

  .horizontal-logo {
      display: none;
  }

  &[data-sidebar-size="sm-hover"] {

      .main-content {
          margin-left: 70px;

          @media (max-width: 1200px) {
              margin-left: 0 !important;
          }
      }

      .logo {

          text-decoration: none;  
          span.logo-lg {
              display: none;
          }

          span.logo-sm {
              display: inline-block;
          }
      }

      .btn-vertical-sm-hover {
          display: inline-block;
      }

    //   @media (min-width: 768px) {
    //       .topnav-hamburger {
    //           display: none;
    //       }
    //   }

      // Side menu
      .navbar-menu {
          width: 70px;

          .btn-vertical-sm-hover {
              display: none;

              i {
                  &.ri-record-circle-line:before {
                      content: "\EB7D";
                  }
              }
          }

          // Sidebar Menu
          .navbar-nav {

              .badge {
                  display: none;
              }

              .menu-title {
                  text-align: center;

                  span {
                      display: none;
                  }

                  i {
                      display: block;
                      line-height: 36px;
                      font-size: 1rem;
                  }
              }

              .nav-link {
                  span {
                      display: none;
                  }

                  i {
                      font-size: 22px;

                      &.las,
                      &.lar,
                      &.lab {
                          font-size: 24px;
                      }
                  }

                  svg {
                      margin-right: 0px;
                  }

                  &:after,
                  &:before {
                      display: none;
                  }
              }

              .menu-dropdown {
                  display: none;
              }
          }

          &:hover {
              width: 250px !important;

              @media (min-width: 1024.99px) {
                  .btn-vertical-sm-hover {
                      display: inline-block;
                  }

                  .navbar-brand-box {
                      text-align: left;
                  }
              }

              .navbar-nav {
                  .menu-dropdown { //.show
                      display: block;
                  }
              }

              .nav-link {

                  i {
                      font-size: 18px;

                      &.las,
                      &.lar,
                      &.lab {
                          font-size: 20px;
                      }
                  }

                  svg {
                      margin-right: 0.665rem;
                  }

                  span,
                  &:after,
                  &:before {
                      display: inline-block;
                  }
              }

              .logo {
                  span.logo-lg {
                      display: inline-block;
                  }

                  span.logo-sm {
                      display: none;
                  }
              }

              .menu-title {
                  text-align: left;

                  span {
                      display: inline-block;
                  }

                  i {
                      display: none;
                  }
              }
          }
      }
  }

  &[data-sidebar-size="sm-hover"],
  &[data-sidebar-size="sm-hover-active"] {
      .navbar-header {
          @media (min-width: 1025px) {
              padding-left: 1.5rem;
          }
      }
  }

  &[data-sidebar-size="sm-hover-active"] {
      .navbar-brand-box {
          text-align: left;
      }

    //   .topnav-hamburger {
    //       display: none;
    //   }

      .btn-vertical-sm-hover {
          display: inline-block;
      }
  }

  &[data-sidebar-size="sm"] {
      @media (min-width: 768px) {
        min-height: auto !important;
      }

      @media (min-width: 1200px) {
        .main-content {
            margin-left: 70px;
        }
      }

      #page-topbar {
          z-index: calc(1002 + 1);
      }

      .navbar-brand-box {
          position: fixed;
          padding: 0;
          width: 70px;
          z-index: 1;
          top: 0;
          background: #FFF;
      }

      .logo {
          span.logo-lg {
              display: none;
          }

          span.logo-sm {
              display: inline-block;
          }
      }

      // Side menu
      .navbar-menu {
          position: fixed;
          width: 70px !important;
          padding-top: 70px;

          .simplebar-mask,
          .simplebar-content-wrapper {
              overflow: visible !important;
          }

          .simplebar-scrollbar,
          .vertical-menu-btn {
              display: none !important;
          }

          .simplebar-offset {
              bottom: 0 !important;
          }

          // Sidebar Menu
          .navbar-nav {

              .badge {
                  display: none !important;
              }

              .menu-title {
                  text-align: center;
                  font-size: 1rem;

                  span {
                      display: none;
                  }

                  i {
                      display: block;
                      line-height: 36px;
                  }
              }

              .nav-link {
                  span {
                      display: none;
                  }

                  i {
                      font-size: 22px;
                  }

                  svg {
                      margin-right: 0px;
                  }

                  &:after,
                  &:before {
                      display: none;
                  }
              }

              .menu-dropdown {
                  display: none;
                  height: auto !important;
              }

              .nav-item {
                  position: relative;

                  &:hover {

                      >a.menu-link {
                          position: relative;
                          width: calc(200px + 70px);
                          color: #FFF;
                          background-color: #1d1d1d;
                          transition: none;

                          .icon-dual {
                              color: #413a3a;
                            //   fill: rgba($white, 0.16);
                          }

                          span {
                              display: inline-block;
                              padding-left: 25px;
                          }

                          &:after {
                              display: block;
                              transform: rotate(90deg);
                              color: #FFF;
                          }
                      }

                      >.menu-dropdown {
                          display: block;
                          left: 70px;
                          position: absolute;
                          width: 200px;
                        //   background: dodgerblue;
                          color: #FFF !important;
                          height: auto !important;
                          padding: 0.5rem 0;
                          border-radius: 0 0 3px 3px;
                          box-shadow:  0 2px 4px rgba(15,34,58,0.12);
                      }
                  }
              }

              .nav-sm {
                  padding: 0;

                  .nav-item {
                      &:hover {
                          >.nav-link {
                            //   color: #FFF !important;

                              &:after {
                                  color: inherit;
                              }
                          }
                      }
                  }

                  .nav-link {
                      &:after {
                          display: block !important;
                          transform: rotate(0deg) !important;
                      }
                  }

                  .menu-dropdown {
                      left: 100% !important;
                      top: 0;
                      border-radius: 3px !important;
                  }
              }
          }
      }
  }

  &[data-sidebar-size="md"] {
      @media (min-width: 768px) {
          .main-content {
              margin-left: 180px;
          }
      }

      .navbar-brand-box {
          width: 180px;
      }

      // Side menu
      .navbar-menu {
          width: 180px !important;

          // Sidebar Menu
          .navbar-nav {
              .nav-link {
                  display: block;
                  text-align: center;
                  padding: 0.45rem 1.5rem *0.35;

                  i {
                      display: block;
                  }

                  svg {
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                  }

                  &:before {
                      display: none !important;
                  }

                  &[data-bs-toggle=collapse]:after {
                      position: relative;
                      display: inline-block;
                      right: 0;
                      top: 3px;
                  }

                  &.menu-link {
                      &[data-bs-toggle=collapse]:after {
                          display: none;
                      }
                  }
              }

              .badge {
                  display: none !important;
              }

              .nav-sm {
                  padding-left: 0;
              }
          }


          .menu-title {
              text-align: center;

              span {
                  text-decoration: underline;
              }
          }
      }
  }

//   &[data-sidebar="dark"] {
//       .navbar-menu {
//           background: $vertical-menu-bg-dark;
//           border-right: 1px solid $vertical-menu-border-dark;

//           .hamburger-icon {
//               span {
//                   background-color: $vertical-menu-item-color-dark;
//               }
//           }

//           .btn-vertical-sm-hover {
//               color: $vertical-menu-item-color-dark;
//           }
//       }

//       .navbar-nav {

//           .nav-link {
//               color: $vertical-menu-item-color-dark;

//               &.active {
//                   color: $vertical-menu-item-active-color-dark;
//               }

//               &[data-bs-toggle=collapse] {
//                   &:after {
//                       color: inherit;
//                   }

//                   &[aria-expanded=true] {
//                       color: $vertical-menu-item-active-color-dark;

//                       &:after {
//                           color: inherit;
//                       }

//                       .icon-dual {
//                           color: $vertical-menu-item-active-color-dark;
//                           fill: rgba($white, 0.10);
//                       }
//                   }
//               }

//               svg {
//                   color: $vertical-menu-item-color-dark;
//                   fill: rgba($white, 0.10);
//               }

//               &:hover {
//                   color: $vertical-menu-item-hover-color-dark;

//                   .icon-dual {
//                       color: $vertical-menu-item-hover-color-dark;
//                       fill: rgba($white, 0.16);
//                   }
//               }

//           }

//           >.nav-item {
//               .nav-link.active {
//                   color: $vertical-menu-item-active-color-dark;

//                   .icon-dual {
//                       color: $vertical-menu-item-hover-color-dark;
//                       fill: rgba($white, 0.16);
//                   }
//               }
//           }

//           .nav-sm {
//               .nav-link {
//                   color: $vertical-menu-sub-item-color-dark;

//                   &:before {
//                       background-color: $vertical-menu-sub-item-color-dark !important;
//                   }

//                   &:hover {
//                       color: $vertical-menu-item-hover-color-dark;

//                       &:before {
//                           background-color: $vertical-menu-item-hover-color-dark !important;
//                       }
//                   }

//                   &.active {
//                       color: $vertical-menu-item-active-color-dark;
//                   }
//               }

//               .nav-sm {
//                   .nav-link {
//                       &:before {
//                           background-color: transparent !important;
//                       }

//                       &:hover {
//                           &:before {
//                               background-color: $vertical-menu-item-hover-color-dark !important;
//                           }
//                       }

//                       &.active {
//                           color: $vertical-menu-item-active-color-dark;

//                           &:before {
//                               background-color: $vertical-menu-item-active-color-dark !important;
//                           }
//                       }
//                   }
//               }
//           }
//       }

//       &[data-sidebar-size="sm"] {
//           .navbar-brand-box {
//               background: $vertical-menu-bg-dark;
//           }

//           // Side menu
//           .navbar-menu {
//               .navbar-nav {
//                   .nav-item {

//                       &:hover {
//                           >.menu-dropdown {
//                               background: $vertical-menu-bg-dark;
//                           }
//                       }
//                   }

//                   .nav-sm {
//                       padding: 0;

//                       .nav-link {
//                           &:after {
//                               display: block !important;
//                               transform: rotate(0deg) !important;
//                           }
//                       }

//                       .nav-item {
//                           &:hover {
//                               >.nav-link {
//                                   color: $vertical-menu-item-active-color-dark;

//                                   &:after {
//                                       color: inherit;
//                                   }
//                               }
//                           }
//                       }

//                       .menu-dropdown {
//                           left: 100% !important;
//                           top: 0;
//                           border-radius: 3px !important;
//                       }
//                   }
//               }
//           }
//       }

//       .menu-title {
//           color: $vertical-menu-title-color-dark;
//       }

//       .logo-dark {
//           display: none;
//       }

//       .logo-light {
//           display: inline-block;
//       }
//   }

  &[data-sidebar="light"] {
      .logo-dark {
          display: inline-block;
      }

      .logo-light {
          display: none;
      }
  }

  &[data-layout-style="detached"] {

      #layout-wrapper,
      .main-content {
          min-height: 100vh;
      }

      @media (min-width: 1024.1px) {
          .main-content {
              position: relative;
          }

          #layout-wrapper {
              max-width: 95%;
              margin: 0 auto;
              padding-left: 1.5rem;
          }

          .navbar-header {
              padding-left: 1.5rem;
          }

          .navbar-menu {
              top: calc(70px + 1.5rem);
              bottom: 1.5rem;
              padding: 0;
              border-right: #FFF;
              border-radius: 5px;
              padding: 10px 0;
              z-index: 1;

              .navbar-brand-box {
                  display: none;
              }
          }

          &[data-sidebar="dark"], 
          &[data-sidebar="gradient"],
          &[data-sidebar="gradient-2"],
          &[data-sidebar="gradient-3"],
          &[data-sidebar="gradient-4"] {
              .logo-dark {
                  display: inline-block;
              }

              .logo-light {
                  display: none;
              }
          }
      }

      .footer {
          border-top: 1px dashed gray;
      }

      .auth-page-wrapper {
          .footer {
              border-top: none;
          }
      }

      &[data-sidebar-size="sm"] {
          @media (min-width: 768px) {

              #layout-wrapper,
              .main-content {
                  min-height: 1400px;
              }
          }
      }


  }
}

.menu-title {
  letter-spacing: .05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 500;

  span {
      padding: 12px 20px;
      display: inline-block;
  }

  i {
      display: none;
  }
}

.vertical-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#1d1d1d, 0.35);
  z-index: 1003;
  display: none;
}

// Mobile Menu
.vertical-sidebar-enable {
  .vertical-overlay {
      display: block;
  }

  .app-menu {
      margin-left: 0 !important;
      z-index: 1004;
  }
}

// rtl
[dir="rtl"] {
  .navbar-menu {
      .navbar-nav {
          .nav-link[data-bs-toggle=collapse] {
              &:after {
                  transform: rotate(180deg);
              }

              &[aria-expanded=true]:after {
                  transform: rotate(270deg);
              }
          }
      }
  }
}

.table-auto {
    .table-responsive {
        height: auto !important
    }
}

.patient-data {
    @media (max-width: 1023.98px) { //1199.98
        width: 100% !important;
    }
}

.toggle-patient-bar {
    transition: 0.5s;
}

[data-patientbar-size="sm"] {
    @media (min-width: 992px) and (max-width: 1023.98px) { // 1199.98
        .toggle-patient-bar {
            transition: 0.5;
            overflow: hidden !important;
            width: 100% !important; //width: 0% !important;
        }
    
        .patient-data {
            width: 100% !important;
            padding-left: 1rem;
        }
    }
}

[toogle-patient-tab="sm"] {
    @media (max-width: 1023.98px) { //1199.98  
        .toogle-patient-tab {
            transition: 0.5s;
            height: 0 !important;
            overflow: hidden !important;
        }
    }
}

[toogle-patient-tab="lg"] {
    .toogle-patient-tab {
        height: auto !important;
    }
}

// [modeOfPayment="Cash"] {
//     .cheque-no {
//         opacity: 0;
//         // width: 0px !important;
//         display: none
//     }
//     .bank-name {
//         opacity: 0;
//         // width: 0px !important;
//         display: none
//     }
//     .card-number {
//         opacity: 0;
//         // width: 0px !important;
//         display: none
//     }
// }

// [modeOfPayment="Card"] {
//     .cheque-no {
//         opacity: 0;
//         // width: 0px !important;
//         display: none
//     }
//     .bank-name {
//         opacity: 0;
//         // width: 0px !important;
//         display: none
//     }
//     .card-number {
//         opacity: 1;
//         // width: auto !important;
//         display: block
//     }
// }

// [modeOfPayment="Cheque"] {
//     .cheque-no {
//         opacity: 1;
//         width: auto !important;
//         display: block;
//     }
//     .bank-name {
//         opacity: 1;
//         // width: auto !important;
//         display: block;
//     }
//     .card-number {
//         opacity: 0;
//         // width: 0px !important;
//         display: none;
//     }
// }

// //gradient sidebar
// [data-sidebar="gradient"],[data-sidebar="gradient-2"], [data-sidebar="gradient-3"], [data-sidebar="gradient-4"] {
//   .navbar-menu {
//       background: $vertical-menu-bg-gradient;
//       border-right: 1px solid $vertical-menu-border-gradient;

//       .hamburger-icon {
//           span {
//               background-color: $vertical-menu-item-color-gradient;
//           }
//       }

//       .btn-vertical-sm-hover {
//           color: $vertical-menu-item-color-gradient;
//       }
//   }

//   .navbar-nav {
      
//       .nav-link {
//           color: $vertical-menu-item-color-gradient;

//           &.active{
//               color: $vertical-menu-item-active-color-dark;
//           }

//           &[data-bs-toggle=collapse]{
//               &:after {
//                   color: inherit;
//               }

//               &[aria-expanded=true]{
//                   color: $vertical-menu-item-active-color-dark;
//                   &:after {
//                       color: inherit;
//                   }

//                   .icon-dual {
//                       color: $vertical-menu-item-active-color-dark;
//                       fill: rgba($white , 0.10);
//                   }
//               }
//           }

//           svg {
//               color:  $vertical-menu-item-color-gradient;
//               fill: rgba($white , 0.10);
//           }

//           &:hover {
//               color: $vertical-menu-item-hover-color-dark;
//               .icon-dual {
//                   color: $vertical-menu-item-hover-color-dark;
//                   fill: rgba($white , 0.16);
//               }
//           }
          
//       }

//       > .nav-item {
//           .nav-link.active {
//               color: $vertical-menu-item-active-color-dark;
//               .icon-dual {
//                   color: $vertical-menu-item-hover-color-dark;
//                   fill: rgba($white , 0.16);
//               }
//           }
//       }

//       .nav-sm {
//           .nav-link {
//               color: $vertical-menu-sub-item-color-gradient;

//               &:before {
//                   background-color: $vertical-menu-sub-item-color-gradient !important;
//               }

//               &:hover {
//                   color: $vertical-menu-item-hover-color-dark;

//                   &:before {
//                       background-color: $vertical-menu-item-hover-color-dark !important;
//                   }
//               }
              
//               &.active {
//                   color: $vertical-menu-item-active-color-dark;
//               }
//           }

//           .nav-sm {
//               .nav-link {
//                   &:before {
//                       background-color: transparent !important;
//                   }

//                   &:hover {
//                       &:before {
//                           background-color: $vertical-menu-item-hover-color-dark !important;
//                       }
//                   }

//                   &.active {
//                       color: $vertical-menu-item-active-color-dark;
//                       &:before {
//                           background-color: $vertical-menu-item-active-color-dark !important;
//                       }
//                   }
//               }
//           }
//       }
//   }

//   &[data-sidebar-size="sm"] {
//       .navbar-brand-box {
//           background: $vertical-menu-bg-gradient;
//       }

//       // Side menu
//       .navbar-menu {
//           .navbar-nav {
//               .nav-item {

//                   &:hover {
//                       > .menu-dropdown {
//                           background: $vertical-menu-bg-dark;
//                       }
//                   }
//               }

//               .nav-sm {
//                   padding: 0;

//                   .nav-link {
//                       &:after {
//                           display: block !important;
//                           transform: rotate(0deg) !important;
//                       }
//                   }

//                   .nav-item {
//                       &:hover {
//                           > .nav-link {
//                               color: $vertical-menu-item-active-color-dark;
  
//                               &:after {
//                                   color: inherit;
//                               }
//                           }
//                       }
//                   }

//                   .menu-dropdown {
//                       left: 100% !important;
//                       top: 0;
//                       border-radius: 3px !important;
//                   }
//               }
//           }
//       }
//       &[data-layout=vertical] {
//           .navbar-menu {
//               .navbar-nav {
//                   .nav-item{
                      
//                       &:hover {
//                           >a.menu-link {
//                               background: $vertical-menu-bg-gradient;
//                           }
//                       }
//                   }
//               }
//           }
//       }
//   }

//   .menu-title {
//       color: $vertical-menu-title-color-gradient;
//   }

//   .logo-dark {
//       display: none;
//   }
  
//   .logo-light {
//       display: inline-block;
//   }
// }

// .bg-vertical-gradient {
//   background: linear-gradient(to right, var(--#{$variable-prefix}primary), var(--#{$variable-prefix}success));;
// }

// .bg-vertical-gradient-2 {
//   background: linear-gradient(to right, var(--#{$variable-prefix}info), var(--#{$variable-prefix}secondary));
// }

// .bg-vertical-gradient-3 {
//   background: linear-gradient(to right, var(--#{$variable-prefix}info), var(--#{$variable-prefix}success));
// }

// .bg-vertical-gradient-4 {
//   background: linear-gradient(to right, var(--#{$variable-prefix}dark), var(--#{$variable-prefix}primary));
// }


// //sidebar-images
// .sidebar-background {
//   position: absolute;
//   z-index: -1;
//   height: 100%;
//   width: 100%;
//   display: block;
//   top: 0;
//   left: 0;
//   background-size: cover;
//   background-position: 50%;
//   opacity: .07;
// }

// [data-sidebar-image="img-1"]{
//   .sidebar-background { 
//       background-image: url('../../images/sidebar/img-1.jpg');
//   }
// }
// [data-sidebar-image="img-2"]{
//   .sidebar-background { 
//       background-image: url('../../images/sidebar/img-2.jpg');
//   }
// }
// [data-sidebar-image="img-3"]{
//   .sidebar-background { 
//       background-image: url('../../images/sidebar/img-3.jpg');
//   }
// }
// [data-sidebar-image="img-4"]{
//   .sidebar-background { 
//       background-image: url('../../images/sidebar/img-4.jpg');
//   }
// }