//
// _popover.scss
//

.popover {
  box-shadow: $popover-box-shadow;
}

.popover__wrapper {
  position: relative;
  // margin-top: 3px;
  // display: inline-block;
}
.popover__content {
  width: 300px !important;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(0, 10px);
  left: 0;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.bottom-aligned {
  top: 20px;

  &::before {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
}

.center-aligned {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) !important;

  &::before {
    left: -8px;
    top: 50% !important;
    transform: translateY(-50%);
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }
}

.top-aligned {
  bottom: 20px;

  &::before {
    border-width: 10px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
  }
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  // width: 10px;
  transition-duration: 0.3s;
  transition-property: transform;
}

/* noraml triangle
-webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
*/

/* upside down triangle
-webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
clip-path: polygon(100% 0, 0 0, 50% 100%);
*/

//   .popover__wrapper:hover .popover__content {
//     z-index: 10;
//     opacity: 1;
//     visibility: visible;
//     transform: translate(0, -20px);
//     transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
//   }

.popover__show {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: center;
}
