//
// _topbar.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: dodgerblue;
  transition: all 0.1s ease-out;

  &.topbar-shadow {
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  }

  @media (min-width: 1200px) {
    // form 768 ////////////////////////////////////////////////////// 3rd modification
    left: 250px;
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 1.5rem 0 calc(1.5rem / 2)
    /*rtl: 0 0 0 calc(#{$grid-gutter-width} / 2) */;

  @media (max-width: 767.98px) {
    padding: 0 calc(1.5rem / 2) 0 calc(1.5rem / 2);
  }

  .topbar-head-dropdown {
    .dropdown-menu.show {
      top: 13px;
    }
  }

  .topbar-head-dropdown.mb-patient-dropdown {
    .dropdown-menu.show {
      top: 55px !important;
    }
  }

  .btn-topbar {
    display: grid;
    place-content: center;
    height: 42px;
    width: 42px;

    i {
      font-size: 20px;
      color: #495057;
    }

    @media (max-width: 360px) {
      height: 36px;
      width: 36px;
    }
  }

  .user-name-text {
    color: #495057;
  }
}

/* Search */

.app-search {
  padding-top: 2rem;
  padding: calc((70px - 38px) / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 30px;
    // background-color: $topbar-search-bg;
    box-shadow: none;
  }

  span.search-widget-icon {
    position: absolute;
    z-index: 10;
    font-size: 18px;
    line-height: 38px;
    left: 13px;
    top: 0;
    // color: $gray-600;
  }

  .search-widget-icon-close {
    right: 7px;
    left: auto !important;
  }

  @media (max-width: 1023.99px) {
    padding-left: calc(1.5rem / 2);
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;

    a {
      color: #495057;
    }
  }
}

@media (max-width: 767.98px) {
  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.header-item {
  height: 70px;
  display: flex;
  align-items: center;
}

.header-profile-user {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

.topbar-badge-sm {
  right: 0;
  top: 7px !important;
}

.topbar-badge {
  background-color: rgb(247, 57, 120) !important;
  right: -9px;
  top: 20px !important;
}

.topbar-user {
  @media (min-width: 768px) {
    background-color: #f3f3f9;
  }

  .dropdown-menu {
    top: 6px !important;
  }
}

.notification-item {
  padding: 0.75rem 1rem;
  white-space: inherit;
  position: relative;

  .form-check-input {
    position: relative;
    z-index: 2;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  border: 1px solid transparent;
  color: #495057;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: gainsboro;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\eacb";
    }
  }
}
// Dark mode enable
// [data-layout-mode="dark"] {
//   .light-dark-mode {
//     .bx-moon::before {
//       content: "\ec34";
//     }
//   }
// }

// [data-topbar="dark"] {
//   #page-topbar {
//     // background-color: $header-bg-dark;
//     // border-color: $header-bg-dark;
//   }

// .navbar-header {
//   .btn-topbar {
//     color: $header-item-color-dark;

//     &:hover,
//     &:focus {
//       background-color: rgba($white, 0.07);
//       color: $white;
//     }
//   }
// }

// .topbar-user {
//   @media (min-width: 767.99px) {
//     background-color: $topbar-user-bg-dark;
//   }

//   .user-name-text {
//     color: rgba($white, 0.85) !important;
//   }

//   .user-name-sub-text {
//     color: $header-item-color-dark !important;
//   }
// }

// .logo-dark {
//   display: none;
// }

// .logo-light {
//   display: inline-block;
// }

// .app-search {
//   .form-control {
//     background-color: rgba($white, 0.05);
//     color: $white;
//   }

//   span.search-widget-icon,
//   input.form-control::-webkit-input-placeholder {
//     color: rgba($white, 0.5);
//   }
// }

// .hamburger-icon {
//   span {
//     background-color: $gray-300;
//   }
// }
// }

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  #search-dropdown-reponsive {
    top: 54px !important;
  }
}

[data-layout="vertical"] {
  &[data-layout-style="detached"] {
    @media (min-width: 1024.1px) {
      #page-topbar {
        left: 0 !important;
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
      }

      .horizontal-logo {
        display: inline-block;
        padding-left: 0;
      }

      .topnav-hamburger {
        visibility: hidden;
        box-shadow: none !important;
        border: none !important;
      }

      .layout-width {
        max-width: 95%;
        margin: 0 auto;
      }

      &[data-sidebar-size="sm"],
      &[data-sidebar-size="sm-hover"] {
        .navbar-brand-box {
          background-color: transparent !important;
          position: relative;
          width: auto;
          text-align: left;

          .logo-sm {
            display: none;
          }

          .logo-lg {
            display: block;
          }
        }
      }
    }

    &[data-topbar="dark"] {
      .horizontal-logo {
        .logo-dark {
          display: none;
        }
        .logo-light {
          display: block;
        }
      }
    }
  }
}

[data-layout="horizontal"] {
  #page-topbar {
    left: 0;
    border-bottom: 1px solid grey;

    @media (min-width: 1024.1px) {
      &.topbar-shadow {
        box-shadow: none;
      }
    }
  }

  .page-content {
    @media (min-width: 1024.1px) {
      margin-top: 70px;
    }

    padding: calc(45px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2);
  }
  &[data-layout-width="boxed"] {
    .page-content {
      @media (min-width: 1024.1px) {
        min-height: calc(100vh - 60px + 70px);
      }
    }
  }
}

[data-layout="vertical"] {
  &[data-sidebar-size="sm"],
  &[data-sidebar-size="sm-hover"] {
    #page-topbar {
      @media (min-width: 768px) {
        left: 70px;
      }
    }
  }

  &[data-sidebar-size="md"] {
    #page-topbar {
      @media (min-width: 768px) {
        left: 180px;
      }
    }
  }
}

// [data-layout="twocolumn"] {
//   #page-topbar {
//     @media (min-width: 768px) {
//       left: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
//     }
//   }

//   .horizontal-logo {
//     display: none;
//   }
// }
